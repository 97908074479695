import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Tween } from "react-gsap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core"

// 登録している Font Awesome Icon を取得する
const fasDotCircle = findIconDefinition({ prefix: "fas", iconName: "dot-circle" })
const fasCheckCircle = findIconDefinition({ prefix: "fas", iconName: "check-circle" })
const fasExclamationCircle = findIconDefinition({ prefix: "fas", iconName: "exclamation-circle" })
const falSadTear = findIconDefinition({ prefix: "fal", iconName: "sad-tear" })
const farPlusCircle = findIconDefinition({ prefix: "far", iconName: "plus-circle" })


/**
 * CalloutMessage component
 *
 * @param {any} style - インラインスタイル
 * @return {*} React Element
 */
interface ICalloutDefaultProps {
    show: boolean,
    type: string,
    style?: any,
    children: any,
}

const CalloutMessage = (props: ICalloutDefaultProps) => {
    const innerContainerRef = useRef(null)
    const [isShow, setIsShow] = useState<boolean>(false)
    const [innerContainerHeight, setInnerContainerHeight] = useState<number>(0)

    useEffect(() => {
        setIsShow(props.show)

        if (innerContainerRef.current != null) {
            // @ts-ignore
            let height = innerContainerRef.current.clientHeight
            if (height != null) setInnerContainerHeight(height)
        }
    }, [])

    useEffect(() => {
        console.log(`change show : ${props.show}`)
        setIsShow(props.show)
    }, [props.show])

    // アイコンの設定
    const icon = (type: string) => {
        if (type == `plain`) return null

        const _icons: { [key: string]: any } = {
            "alert": <FontAwesomeIcon icon={fasExclamationCircle} size="2x" fixedWidth/>,
            "warning": <FontAwesomeIcon icon={fasExclamationCircle} size="2x" fixedWidth/>,
            "success": <FontAwesomeIcon icon={fasCheckCircle} size="2x" fixedWidth/>,
        }

        return (
            <div className="__icon">
                {_icons[type]}
            </div>
        )
    }

    // スタイルクラスの設定
    let styleClass = "callout-message-style--plain"
    if (props.type == "alert") styleClass = "callout-message-style--alert"
    if (props.type == "warning") styleClass = "callout-message-style--warning"
    if (props.type == "success") styleClass = "callout-message-style--success"

    return (
        <Tween
            to={{
                height: isShow ? `${innerContainerHeight}px` : 0,
                //marginBottom: isShow ? `1rem` : 0,
                opacity: isShow ? 1 : 0,
            }}
        >
            <div
                className={`callout-message ${styleClass}`}
                style={{
                    height: 0,
                    marginBottom: 0,
                    opacity: 0,
                    ...props.style,
                }}
            >
                <div
                    ref={innerContainerRef}
                    className="callout-message__inner-container"
                >
                    {icon(props.type)}
                    <div className="__message">{props.children}</div>
                </div>
            </div>
        </Tween>
    )
}

CalloutMessage.propTypes = {
    type: PropTypes.oneOf(["plain", "alert", "warning", "success"]),
}

CalloutMessage.defaultProps = {
    show: false,
    type: "plain",
}


export default CalloutMessage