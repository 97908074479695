import React, {Fragment, useEffect, useState, useRef} from "react"
import {graphql, useStaticQuery, Link} from "gatsby"
import PropTypes from "prop-types"
import {useBreadcrumb} from "gatsby-plugin-breadcrumb"
import {Timeline as RTimeline, Tween as RTween} from "react-gsap"
import {gsap} from "gsap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {findIconDefinition} from "@fortawesome/fontawesome-svg-core"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"
import Lottie from "react-lottie"
// Memo : IE11対応 - IE11がurlSearchParamsが使えないのでポリフィルで対応する。IE11が不要になれば削除する。
import "url-search-params-polyfill"


// アニメーションアイコン
// @ts-ignore
import animationDataSendMailOk from "../components/animationData/contact_mail_send_ok_icon.json"
// @ts-ignore
import animationDataSendMailNg from "../components/animationData/contact_mail_send_ng_icon.json"


// Components
import SEO from "../components/Seo"
import LayoutBase from "../components/Layout"
import PageHeader from "../components/PageHeader"
import CalloutMessage from "../components/CalloutMessage"
import {HandlingPersonalInformation, HandlingInformationWebsite} from "./policy"


// Style
import "../styles/pages/contact.scss"


// 登録している Font Awesome Icon を取得する
const fasDotCircle = findIconDefinition({prefix: "fas", iconName: "dot-circle"})
const fasCheckCircle = findIconDefinition({prefix: "fas", iconName: "check-circle"})
const fasExclamationCircle = findIconDefinition({prefix: "fas", iconName: "exclamation-circle"})
const farPlusCircle = findIconDefinition({prefix: "far", iconName: "plus-circle"})
const farUndoAlt = findIconDefinition({prefix: "far", iconName: "undo-alt"})


// メールの送信設定
//
// メールの送信は PHP側 なので以下のファイル内で設定している
// /static/contact/contactsend.php


// お問い合わせ種類のカテゴリデータ
const categoryData = [
    {
        id: "0000",
        label: "選択してください",
    },
    {
        id: "0100",
        label: "製品開発（受託・請負）",
    },
    {
        id: "1100",
        label: "HEMS制御盤",
    },
    {
        id: "9100",
        label: "採用募集",
    },
    {
        id: "9900",
        label: "その他",
    },
]

// 製品開発のカテゴリデータ
const productDevelopmentCategoryData = [
    {
        id: "0000",
        label: "選択してください",
    },
    {
        id: "0101",
        label: "全般",
    },
    {
        id: "0102",
        label: "回路・基板設計",
    },
    {
        id: "0103",
        label: "機械・機構設計",
    },
    {
        id: "0104",
        label: "ソフトウェア開発",
    },
    {
        id: "0105",
        label: "デザイン開発",
    },
    {
        id: "0106",
        label: "製作・実装・組立",
    },
    {
        id: "0107",
        label: "評価試験",
    },
    {
        id: "0108",
        label: "量産支援",
    },
    {
        id: "0199",
        label: "その他",
    },
]

// 求人のカテゴリデータ
const jobCategoryData = [
    {
        id: "0000",
        label: "選択してください",
    },
    {
        id: "9101",
        label: "全般",
    },
    {
        id: "9102",
        label: "営業",
    },
    {
        id: "9103",
        label: "営業補助",
    },
    {
        id: "9104",
        label: "プログラマー",
    },
    {
        id: "9105",
        label: "ハードウェアエンジニア",
    },
    {
        id: "9106",
        label: "デザイナー",
    },
    {
        id: "9107",
        label: "アルバイト / パートタイマー",
    },
    {
        id: "9199",
        label: "その他",
    },
]

// Google reCAPTCHA の API Key
const reCaptchaSiteKey = "6LfTyd8UAAAAACb_qfKiqoMbxPyBqztZEs1M9wVu"


/**
 * Contact Component
 *
 * @param location {any} -
 * @return React Element {*}
 *
 * URLパラメーターあり
 * c1 ... 問い合わせ種別　メイン選択項目
 * c2 ... 問い合わせ種別　サブ選択項目
 */
interface IContactDefaultProps {
    location: any,
}

const Contact = (props: IContactDefaultProps) => {
    const query = useStaticQuery(graphql`
        query {
            company_pageHeader_backgroundImage: file(relativePath: {eq: "contact_pageHeader_backgroundImage.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 2500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
        }
    `)
    const pageId = "contact"
    const pageTitle = "お問い合わせ"
    const location = props.location
    const {crumbs} = useBreadcrumb({
        location: location,
        crumbLabel: pageTitle,
    })

    return (
        <Fragment>
            <SEO pageTitle={pageTitle}/>
            <LayoutBase
                pageId={pageId}
                crumbs={crumbs}
                location={location}
            >
                <PageHeader
                    title={pageTitle}
                    backgroundImage={query.company_pageHeader_backgroundImage.childImageSharp.fluid}
                    indicators={true}
                />
                <div className="common-content-layout common-content-format">
                    <ContactForm className="common-content-layout__section"/>
                </div>
            </LayoutBase>
        </Fragment>
    )
}


/**
 * お問い合わせフォーム
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface IContactFormDefaultProps {
    className?: string,
}

const ContactForm = (props: IContactFormDefaultProps) => {

    // GSAP用（react-gsapではない）
    const tween = gsap.timeline({paused: true})


    // URL引数
    const [urlParams, setUrlParams] = useState<any | undefined>([])


    // 名前の値
    const [nameValue, setNameValue] = useState<string | undefined>(undefined)
    // 準備の状態
    const [nameIsReady, setNameIsReady] = useState<boolean | undefined>(undefined)
    // 入力内容に問題がある
    const [nameError, setNameError] = useState<boolean | undefined>(undefined)
    // 入力不可にする
    const [nameDisabled, setNameDisabled] = useState<boolean>(false)


    // メールアドレスの値
    const [emailValue, setEmailValue] = useState<string | undefined>(undefined)
    // 準備の状態
    const [emailIsReady, setEmailIsReady] = useState<boolean | undefined>(undefined)
    // 入力内容に問題がある
    const [emailError, setEmailError] = useState<boolean | undefined>(undefined)
    // 入力不可にする
    const [emailDisabled, setEmailDisabled] = useState<boolean>(false)


    // 電話番号の値
    const [phoneNumberValue, setPhoneNumberValue] = useState<string | undefined>(undefined)
    // 準備の状態
    const [phoneNumberIsReady, setPhoneNumberIsReady] = useState<boolean | undefined>(undefined)
    // 入力内容に問題がある
    const [phoneNumberError, setPhoneNumberError] = useState<boolean | undefined>(undefined)
    // 入力不可にする
    const [phoneNumberDisabled, setPhoneNumberDisabled] = useState<boolean>(false)


    // 問い合わせ種別のメイン値
    const [category1Value, setCategory1Value] = useState<string>("選択してください")
    // 問い合わせの種別 のサブ値
    const [category2Value, setCategory2Value] = useState<string>("選択してください")
    // 準備の状態
    const [categoryIsReady, setCategoryIsReady] = useState<boolean | undefined>(undefined)
    // 選択を完了していない
    const [categoryError, setCategoryError] = useState<boolean | undefined>(undefined)
    // 入力不可にする
    const [categoryDisabled, setCategoryDisabled] = useState<boolean>(false)


    // 本文の値
    const [inquiryBodyValue, setInquiryBodyValue] = useState<string | undefined>(undefined)
    // 本文の最大文字数
    const inquiryBodyMaxNumberCharacters = 2000
    // 入力している文字数
    const [inquiryBodyCountCharacters, setInquiryBodyCountCharacters] = useState(0)
    // 準備の状態
    const [inquiryBodyIsReady, setInquiryBodyIsReady] = useState<boolean | undefined>(undefined)
    // 空白文字のみが入力されている
    const [inquiryBodyErrorSpaceCharacter, setInquiryBodyErrorSpaceCharacter] = useState<boolean | undefined>(undefined)
    // 最大文字数を超えている
    const [inquiryBodyErrorExceededNumberCharacters, setInquiryBodyErrorExceededNumberCharacters] = useState<boolean | undefined>(undefined)
    // 入力不可にする
    const [inquiryBodyDisabled, setInquiryBodyDisabled] = useState<boolean>(false)


    // チェックの状態
    const [agreeChecked, setAgreeChecked] = useState<boolean>(false)
    // 準備の状態
    const [agreeIsReady, setAgreeIsReady] = useState<boolean>(false)
    // 入力不可にする
    const [agreeDisabled, setAgreeDisabled] = useState<boolean>(false)


    // Google reCAPTCHAの値
    const [reCaptchaValue, setReCaptchaValue] = useState<string>("")
    // Google reCAPTCHAの状態
    const [reCaptchaNoBots, setReCaptchaNoBots] = useState<boolean>(false)


    // 準備の状態
    const [reCaptchaIsReady, setReCaptchaIsReady] = useState<boolean>(false)
    // すべての入力フォームの準備状態
    const [allIsReady, setAllIsReady] = useState<boolean>(false)


    // 送信した
    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    // 送信Tween状態
    const [sendTweenState, setSendTweenState] = useState<string>("pause")


    // 送信状態
    const [sent, setSent] = useState<boolean | undefined>(undefined)
    // 送信完了メッセージのTween状態
    const [sendOkTweenState, setSendOkTweenState] = useState<string>("stop")
    // 送信完了メッセージのTween状態
    const [sendOkMessageTweenState, setSendOkMessageTweenState] = useState<string>("stop")
    // 送信エラーのTween状態
    const [sendNgTweenState, setSendNgTweenState] = useState<string>("stop")
    // 送信エラーメッセージのTween状態
    const [sendNgMessageTweenState, setSendNgMessageTweenState] = useState<string>("stop")
    // アニメーションアイコン用のフラグ
    const [animationIconSendOkStop, setAnimationIconSendOkStop] = useState<boolean>(true)
    const [animationIconSendNgStop, setAnimationIconSendNgStop] = useState<boolean>(true)


    // 規約
    const termsInlineRef = useRef(null)
    const [isTermsOpened, setIsTermsOpened] = useState<boolean>(true)


    // マウント時の処理
    useEffect(() => {
        // 規約にスタイルを付ける
        if (isTermsOpened) {
            addAnimationTermsOpen(tween, true)
        } else {
            addAnimationTermsClose(tween, true)
        }
        tween.play()

        // URL引数を取得する
        const _urlParams = getUrlParams()
        if (Object.keys(_urlParams).length > 0) {
            setUrlParams(_urlParams)
        }
    }, [])


    // 名前の準備確認
    useEffect(() => {
        if (nameError == undefined) {
            setNameIsReady(undefined)
            return
        }

        if (nameError) {
            setNameIsReady(false)
        } else {
            setNameIsReady(true)
        }
    }, [nameError])


    // メールアドレスの準備確認
    useEffect(() => {
        if (emailError == undefined) {
            setEmailIsReady(undefined)
            return
        }

        if (emailError) {
            setEmailIsReady(false)
        } else {
            setEmailIsReady(true)
        }
    }, [emailError])


    // 電話番号の準備確認
    useEffect(() => {
        if (phoneNumberError == undefined) {
            setPhoneNumberIsReady(undefined)
            return
        }

        if (phoneNumberError) {
            setPhoneNumberIsReady(false)
        } else {
            setPhoneNumberIsReady(true)
        }
    }, [phoneNumberError])


    // カテゴリーの準備確認
    useEffect(() => {
        if (categoryError == undefined) {
            setCategoryIsReady(undefined)
            return
        }

        if (categoryError) {
            setCategoryIsReady(false)
        } else {
            setCategoryIsReady(true)
        }
    }, [categoryError])


    // 本文の準備確認
    useEffect(() => {
        let undefinedCount = 0
        let errorCount = 0

        if (inquiryBodyErrorSpaceCharacter == undefined) ++undefinedCount
        if (inquiryBodyErrorExceededNumberCharacters == undefined) ++undefinedCount
        if (undefinedCount >= 2) {
            setInquiryBodyIsReady(undefined)
            return
        }

        if (inquiryBodyErrorSpaceCharacter) ++errorCount
        if (inquiryBodyErrorExceededNumberCharacters) ++errorCount
        if (errorCount <= 0) {
            setInquiryBodyIsReady(true)
        } else {
            setInquiryBodyIsReady(false)
        }

    }, [inquiryBodyErrorSpaceCharacter, inquiryBodyErrorExceededNumberCharacters])


    // 本文の文字数を数える
    useEffect(() => {
        inquiryBodyCountNumberCharacters()
    }, [inquiryBodyValue])


    // reCAPTCHAの準備確認
    useEffect(() => {
        if (reCaptchaNoBots) {
            setReCaptchaIsReady(true)
            return
        }

        setReCaptchaIsReady(false)
    }, [reCaptchaNoBots])


    // 規約同意の準備確認
    useEffect(() => {
        if (agreeChecked) {
            setAgreeIsReady(true)
            return
        }

        setAgreeIsReady(false)
    }, [agreeChecked])


    // すべての入力フォームの準備確認
    useEffect(() => {
        const isReadyMaximumNumber = 7
        let count = 0

        if (nameIsReady) ++count
        if (emailIsReady) ++count
        if (phoneNumberIsReady || phoneNumberIsReady == undefined) ++count
        if (categoryIsReady) ++count
        if (inquiryBodyIsReady) ++count
        if (agreeIsReady) ++count
        if (reCaptchaIsReady) ++count

        if (count >= isReadyMaximumNumber) {
            setAllIsReady(true)
            return
        }

        setAllIsReady(false)

    }, [nameIsReady, emailIsReady, phoneNumberIsReady, categoryIsReady, inquiryBodyIsReady, agreeIsReady, reCaptchaIsReady])


    // 送信した
    useEffect(() => {
        if (isSubmit) {
            setSendTweenState("play")
        }
    }, [isSubmit])


    // 送信状態
    useEffect(() => {
        if (sent === undefined) return

        if (sent) {
            setSendOkTweenState("play")
        } else {
            setSendNgTweenState("play")
        }
    }, [sent])


    // URL引数を返す
    const getUrlParams = () => {
        let params: any = {}

        if (typeof window !== undefined) {
            const query = window.location.href.split("?")[1]
            if (query) {
                const rawParams = query.split("&")
                rawParams.forEach((rawParam, i) => {
                    const kv = rawParam.split("=")
                    params[kv[0]] = kv[1]
                })
            }

            return params
        }
    }


    // スペース、タブ、改ページ、改行などの空白文字のみであれば true を返す
    const checkSpaceCharacterOnly = (text: string) => {
        return text.match(/^\s+$/g) != null
    }


    // メールアドレスの書式を満たしていれば true を返す
    const checkEmailFormat = (text: string) => {
        return text.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) != null
    }


    // 電話番号の書式を満たしていれば true を返す
    const checkPhoneNumberFormat = (text: string) => {
        return text.match(/^\+\d+$|^\d+$/) != null
    }


    // 文頭文末のスペースを削除して返す
    const removeLeadingAndTrailingSpaces = (text: string) => {
        return text.replace(/^[\x20\u3000\t]+|[\x20\u3000\t]+$/mg, "")
    }


    // 文末のスペースを削除して返す
    const removeTrailingSpaces = (text: string) => {
        return text.replace(/[\x20\u3000\t]+$/mg, "")
    }


    // 隣接するスペースを結合して返す（全角スペースは半角スペースになる）
    const combineContiguousSpaces = (text: string) => {
        return text.replace(/[\x20\u3000\t]+/g, "\x20")
    }


    // 全角を半角に変換して返す
    const convertFullWidthToHalfWidth = (text: string) => {
        return text.replace(/[！-～]/g, (_text: string) => {
            return String.fromCharCode(_text.charCodeAt(0) - 0xFEE0)
        })
    }


    // 名前の変更
    const nameHandleChange = (event: any) => {
        setNameValue(event.target.value)
    }


    // 名前のバリデーション
    const nameValidationCheck = () => {

        const value = nameValue

        // 空欄である
        if (value == undefined || value.length <= 0) {
            setNameError(undefined)
            return
        }

        // 空白文字のみが入力されているか？
        if (checkSpaceCharacterOnly(value)) {
            setNameError(true)
            return
        }

        // 文頭文末のスペースを削除する
        let _value = removeLeadingAndTrailingSpaces(value)

        // 連続する全角/半角スペースを半角スペース1つに置換する
        _value = combineContiguousSpaces(_value)

        // 変更した内容を反映する
        setNameValue(_value)

        setNameError(false)
    }


    // メールアドレスの変更
    const emailHandleChange = (event: any) => {
        setEmailValue(event.target.value)
    }


    // メールアドレスのバリデーション
    const emailValidationCheck = () => {
        const value = emailValue

        // 空欄である
        if (value == undefined || value.length <= 0) {
            setEmailError(undefined)
            return
        }

        // 空白文字のみが入力されているか？
        if (checkSpaceCharacterOnly(value)) {
            setEmailError(true)
            return
        }

        // 文頭文末のスペースを削除する
        let _value = removeLeadingAndTrailingSpaces(value)

        // 変更した内容を反映する
        setEmailValue(_value)

        // メールアドレスの書式を満たしていない
        if (!checkEmailFormat(_value)) {
            setEmailError(true)
            return
        }

        setEmailError(false)
    }


    // 電話番号の変更
    const phoneNumberHandleChange = (event: any) => {
        setPhoneNumberValue(event.target.value)
    }


    // 電話番号のバリデーション
    const phoneNumberValidationCheck = () => {
        const value = phoneNumberValue

        // 空欄である
        if (value == undefined || value.length <= 0) {
            setPhoneNumberError(undefined)
            return
        }

        // 文頭文末のスペースを削除する
        let _value = removeLeadingAndTrailingSpaces(value)

        // 全角を半角に変換する
        _value = convertFullWidthToHalfWidth(_value)

        // ハイフンと括弧を削除する
        _value = _value.replace(/-/g, "").replace(/\(/g, "").replace(/\)/g, "")

        // 変更した内容を反映する
        setPhoneNumberValue(_value)

        // 電話番号の書式を満たしていない
        if (!checkPhoneNumberFormat(_value)) {
            setPhoneNumberError(true)
            return
        }

        setPhoneNumberError(false)
    }


    // 問い合わせ種別1の変更
    const category1HandleChange = (id: string) => {
        setCategory1Value(id)
    }


    // 問い合わせ種別2の変更
    const category2HandleChange = (id: string) => {
        setCategory2Value(id)
    }


    // 問い合わせ種別のバリデーション
    const categorySelectValidationCheck = (isReady: boolean | undefined) => {
        if (isReady == undefined) {
            setCategoryError(undefined)
            return
        }

        if (!isReady) {
            setCategoryError(true)
            return
        }

        setCategoryError(false)
    }


    // 本文の変更
    const inquiryBodyHandleChange = (event: any) => {
        setInquiryBodyValue(event.target.value)
    }


    // 本文のバリデーション
    const inquiryBodyValidationCheck = () => {
        const value = inquiryBodyValue

        // 本文が空欄か？
        if (value == undefined || value.length <= 0) {
            setInquiryBodyErrorSpaceCharacter(undefined)
            return
        }

        // 空白文字のみが入力されているか？
        if (checkSpaceCharacterOnly(value)) {
            setInquiryBodyErrorSpaceCharacter(true)
            return
        }

        // 文末のスペースを削除する
        let _value = removeTrailingSpaces(value)

        // 4行以上連続した改行を4行にする
        _value = _value.replace(/\r\n{4,}|\r{4,}|\n{4,}/g, "\n\n\n\n")

        // 変更した内容を反映する
        setInquiryBodyValue(_value)

        setInquiryBodyErrorSpaceCharacter(false)
    }


    // 本文の文字数を確認する
    const inquiryBodyCountNumberCharacters = () => {
        const value = inquiryBodyValue

        if (value == undefined) return

        const count = value.length
        setInquiryBodyCountCharacters(count)

        // 入力されていない
        if (count <= 0) {
            setInquiryBodyErrorExceededNumberCharacters(undefined)
            return
        }

        // 最大文字数を超えているか？
        if (count > inquiryBodyMaxNumberCharacters) {
            setInquiryBodyErrorExceededNumberCharacters(true)
            return
        }

        setInquiryBodyErrorExceededNumberCharacters(false)
    }


    // 同意を確認する
    const agreeValidationCheck = () => {
        setAgreeChecked(!agreeChecked)
    }


    // reCAPTCHAを確認する
    const reCaptchaCheck = (value: any) => {
        if (value !== null && value !== "") {
            setReCaptchaValue(value)
            setReCaptchaNoBots(true)
            return
        }

        setReCaptchaValue("")
        setReCaptchaNoBots(false)
    }


    // 入力フォームを施錠する
    const lockInputForm = () => {
        setNameDisabled(true)
        setEmailDisabled(true)
        setPhoneNumberDisabled(true)
        setCategoryDisabled(true)
        setInquiryBodyDisabled(true)
        setAgreeDisabled(true)
    }


    // 入力フォームを解錠する
    const unlockInputForm = () => {
        setNameDisabled(false)
        setEmailDisabled(false)
        setPhoneNumberDisabled(false)
        setCategoryDisabled(false)
        setInquiryBodyDisabled(false)
        setAgreeDisabled(false)
    }


    // 送信をもう一度試す
    const handleRetry = () => {
        unlockInputForm()
        setIsSubmit(false)
        setSent(undefined)
        setAnimationIconSendOkStop(true)
        setAnimationIconSendNgStop(true)

        if (sendOkTweenState == "play") {
            setSendOkTweenState("stop")
            setSendOkMessageTweenState("stop")
        }

        if (sendNgTweenState == "play") {
            setSendNgTweenState("stop")
            setSendNgMessageTweenState("stop")
        }

        setSendTweenState("reverse")
    }


    // 送信する
    const handleSubmit = (event: any) => {
        event.preventDefault()

        lockInputForm()

        const name = String(nameValue)
        const email = String(emailValue)
        const phoneNumber = phoneNumberValue !== undefined ? String(phoneNumberValue) : ""


        // 問い合わせ種別
        let category1 = ""
        for (let i in categoryData) {
            if (category1Value == categoryData[i].id) category1 = categoryData[i].label
        }

        let inquiryType = category1

        // 製品開発の問い合わせ種別
        if (category1Value == categoryData[1].id) {
            let category2 = ""
            for (let i in productDevelopmentCategoryData) {
                if (category2Value == productDevelopmentCategoryData[i].id) category2 = productDevelopmentCategoryData[i].label
            }
            inquiryType = `${category1} / ${category2}`
        }

        // 求人の問い合わせ種別
        if (category1Value == categoryData[3].id) {
            let category2 = ""
            for (let i in jobCategoryData) {
                if (category2Value == jobCategoryData[i].id) category2 = jobCategoryData[i].label
            }
            inquiryType = `${category1} / ${category2}`
        }


        const inquiryBody = String(inquiryBodyValue)
        const reCaptcha = reCaptchaValue

        let params = new URLSearchParams()
        params.append("name", name)
        params.append("email", email)
        params.append("phone_number", phoneNumber)
        params.append("inquiry_type", inquiryType)
        params.append("inquiry_body", inquiryBody)
        params.append("re_captcha", reCaptcha)

        setIsSubmit(true)

        axios.post("./contactsend.php", params)
            .then(response => {
                const internalMailSend = response.data.internal_mail_send
                const internalMailError = response.data.internal_mail_error
                const replayMailSend = response.data.replay_mail_send
                const replayMailError = response.data.replay_mail_error

                if (!internalMailSend) {
                    setSent(false)
                    return
                }

                if (!replayMailSend) {
                    setSent(false)
                    return
                }

                setSent(true)
            }).catch(error => {
            setSent(false)
        })
    }


    // 規約を開くアニメーションを追加する
    const addAnimationTermsOpen = (tween: any, immediate: boolean = false) => {
        tween
            .to(termsInlineRef.current, {
                duration: immediate ? 0 : 0.4,
                height: 400,
                marginTop: 50,
            })
    }


    // 規約を閉じるアニメーションを追加する
    const addAnimationTermsClose = (tween: any, immediate: boolean = false) => {
        tween
            .to(termsInlineRef.current, {
                duration: immediate ? 0 : 0.4,
                height: 0,
                marginTop: 0,
            })
    }


    // 規約を開く
    const termsOpen = () => {
        setIsTermsOpened(true)

        addAnimationTermsOpen(tween)
        tween.play()
    }


    // 規約を閉じる
    const termsClose = () => {
        setIsTermsOpened(false)

        addAnimationTermsClose(tween)
        tween.play()
    }


    // 規約をトグルする
    const termsToggle = () => {
        if (isTermsOpened) {
            termsClose()
            return
        }

        termsOpen()
    }


    return (
        <div className={`grid-container ${props.className}`}>
            <div id="contactForm" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h1>お問い合わせ</h1>
                    <form onSubmit={handleSubmit}>
                        <table className="hover stack common-table">
                            <tbody>
                            <tr>
                                <TableHeader
                                    type="required"
                                    headerText="お名前"
                                    validationCheck={nameIsReady}
                                />
                                <td>
                                    <label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={nameValue}
                                            placeholder="山田 太郎"
                                            onChange={nameHandleChange}
                                            onBlur={nameValidationCheck}
                                            disabled={nameDisabled}
                                            tabIndex={1}
                                        />
                                        <CalloutMessage
                                            show={nameError}
                                            type="alert"
                                        >
                                            <p>有効な名前を入力してください</p>
                                        </CalloutMessage>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <TableHeader
                                    type="required"
                                    headerText="メールアドレス"
                                    validationCheck={emailIsReady}
                                    containerClassName="pages-contact-form-mail"
                                />
                                <td className="pages-contact-form-mail">
                                    <label>
                                        <input
                                            type="text"
                                            name="email"
                                            value={emailValue}
                                            placeholder="mail@domein.com"
                                            onChange={emailHandleChange}
                                            onBlur={emailValidationCheck}
                                            disabled={emailDisabled}
                                            tabIndex={2}
                                        />
                                        <CalloutMessage
                                            show={emailError}
                                            type="alert"
                                        >
                                            <p>有効なメールアドレスを入力してください</p>
                                            <ul>
                                                <li>半角英数で入力してください</li>
                                                <li>スペースは使用できません</li>
                                            </ul>
                                        </CalloutMessage>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <TableHeader
                                    type="any"
                                    headerText="電話番号"
                                    validationCheck={phoneNumberIsReady}
                                />
                                <td>
                                    <label>
                                        <input
                                            type="text"
                                            name="phoneNumber"
                                            value={phoneNumberValue}
                                            placeholder="0300001111"
                                            onChange={phoneNumberHandleChange}
                                            onBlur={phoneNumberValidationCheck}
                                            disabled={phoneNumberDisabled}
                                            tabIndex={3}
                                        />
                                        <CalloutMessage
                                            show={phoneNumberError}
                                            type="alert"
                                        >
                                            <p>数字で入力してください</p>
                                            <ul>
                                                <li>国際番号を表す先頭の + は入力できます</li>
                                                <li>ハイフン、丸括弧は取り除かれます。それ以外の文字、記号は入力できません</li>
                                            </ul>
                                        </CalloutMessage>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <TableHeader
                                    type="required"
                                    headerText="問い合わせ種別"
                                />
                                <td>
                                    <CategorySelect
                                        category1Value={category1Value}
                                        category2Value={category2Value}
                                        disabled={categoryDisabled}
                                        category1ChangeCallback={(id: string) => {
                                            category1HandleChange(id)
                                        }}
                                        category2ChangeCallback={(id: string) => {
                                            category2HandleChange(id)
                                        }}
                                        isReadyCallback={(isReady: boolean | undefined) => {
                                            categorySelectValidationCheck(isReady)
                                        }}
                                        urlParams={urlParams}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <TableHeader
                                    type="required"
                                    headerText="本文"
                                    validationCheck={inquiryBodyIsReady}
                                />
                                <td>
                                    <label>
                                        <span
                                            style={{
                                                color: inquiryBodyErrorExceededNumberCharacters ? "#ff0000" : "#000000",
                                            }}
                                        >
                                            {inquiryBodyCountCharacters} / {inquiryBodyMaxNumberCharacters}
                                        </span>
                                        <textarea
                                            name="inquiryBody"
                                            rows={12}
                                            value={inquiryBodyValue}
                                            placeholder="内容"
                                            onChange={inquiryBodyHandleChange}
                                            onBlur={inquiryBodyValidationCheck}
                                            disabled={inquiryBodyDisabled}
                                            tabIndex={5}
                                        />
                                        <CalloutMessage
                                            show={inquiryBodyErrorSpaceCharacter}
                                            type="alert"
                                        >
                                            <p>空白文字だけの記入はできません</p>
                                        </CalloutMessage>
                                        <CalloutMessage
                                            show={inquiryBodyErrorExceededNumberCharacters}
                                            type="warning"
                                        >
                                            <p>入力できる最大文字数を超えています</p>
                                        </CalloutMessage>
                                    </label>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className="pages-contact-agree">
                            <div className="__checkbox">
                                <p>お問い合わせの情報は、プライバシーポリシーに沿って扱われます。</p>

                                <div className="pages-contact-terms">
                                    <div className="pages-contact-terms__title" onClick={termsToggle}>プライバシーポリシー</div>
                                    <div
                                        ref={termsInlineRef}
                                        className="pages-contact-terms__inline"
                                    >
                                        <HandlingPersonalInformation style={{marginBottom: "50px"}}/>
                                        <HandlingInformationWebsite/>
                                    </div>
                                </div>

                                <label
                                    className="pages-contact-agree__text2"
                                    htmlFor="agree"
                                >
                                    プライバシーポリシーを確認した上で同意する
                                </label>
                                <input
                                    onChange={agreeValidationCheck}
                                    id="agree"
                                    type="checkbox"
                                    defaultChecked={agreeChecked}
                                    disabled={agreeDisabled}
                                />
                                <label htmlFor="agree" className="pages-contact-agree-check-box"/>
                            </div>

                            <RTimeline
                                target={
                                    <div className="__submit">
                                        <div
                                            style={{marginBottom: "1rem"}}
                                        >
                                            <ReCAPTCHA
                                                sitekey={reCaptchaSiteKey}
                                                onChange={(value: any) => reCaptchaCheck(value)}
                                            />
                                        </div>
                                        <SubmitButton
                                            label="送信する"
                                            isReady={allIsReady}
                                        />
                                    </div>
                                }
                                playState={sendTweenState}
                                paused
                            >
                                <RTween
                                    from={{
                                        height: "250px",
                                        paddingTop: "2rem",
                                        paddingBottom: "2rem",
                                        opacity: 1,
                                    }}
                                    to={{
                                        height: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        opacity: 0,
                                    }}
                                />
                                <RTween
                                    from={{
                                        height: 0,
                                        opacity: 0,
                                    }}
                                    to={{
                                        height: "350px",
                                        opacity: 1,
                                    }}
                                    playState={sendOkTweenState}
                                    onComplete={() => {
                                        setAnimationIconSendOkStop(false)
                                    }}
                                    paused
                                >
                                    <div className="__status">
                                        <Lottie
                                            options={{
                                                loop: false,
                                                autoplay: false,
                                                animationData: animationDataSendMailOk,
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice",
                                                },
                                            }}
                                            isStopped={animationIconSendOkStop}
                                            eventListeners={
                                                [{
                                                    eventName: "complete",
                                                    callback: () => {
                                                        setSendOkMessageTweenState("play")
                                                    },
                                                }]
                                            }
                                            height={150}
                                            width={150}
                                        />
                                        <RTween
                                            from={{
                                                opacity: 0,
                                            }}
                                            to={{
                                                opacity: 1,
                                            }}
                                            playState={sendOkMessageTweenState}
                                            paused
                                        >
                                            <div className="pages-contact-send-ok-message">
                                                <p className="pages-contact-send-ok-message__text-emphasize">メッセージを送信しました。</p>
                                                <a
                                                    href="/contact/"
                                                    className="button large"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={farUndoAlt}
                                                        size="1x" fixedWidth
                                                    />
                                                    <span style={{marginLeft: "0.5rem"}}>戻る</span>
                                                </a>
                                            </div>
                                        </RTween>
                                    </div>
                                </RTween>
                                <RTween
                                    from={{
                                        height: 0,
                                        opacity: 0,
                                    }}
                                    to={{
                                        height: "300px",
                                        opacity: 1,
                                    }}
                                    playState={sendNgTweenState}
                                    onComplete={() => {
                                        setAnimationIconSendNgStop(false)
                                    }}
                                    paused
                                >
                                    <div className="__status">
                                        <Lottie
                                            options={{
                                                loop: false,
                                                autoplay: false,
                                                animationData: animationDataSendMailNg,
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice",
                                                },
                                            }}
                                            isStopped={animationIconSendNgStop}
                                            eventListeners={
                                                [{
                                                    eventName: "complete",
                                                    callback: () => {
                                                        setSendNgMessageTweenState("play")
                                                    },
                                                }]
                                            }
                                            height={150}
                                            width={150}
                                        />
                                        <RTween
                                            from={{
                                                opacity: 0,
                                            }}
                                            to={{
                                                opacity: 1,
                                            }}
                                            playState={sendNgMessageTweenState}
                                            paused
                                        >
                                            <div className="pages-contact-send-ng-message">
                                                <p className="pages-contact-send-ng-message__text-emphasize">メッセージの送信に失敗しました。ご不便をおかけして申し訳ありません。</p>
                                                <a
                                                    className="button large"
                                                    onClick={handleRetry}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={farUndoAlt}
                                                        size="1x" fixedWidth
                                                    />
                                                    <span style={{marginLeft: "0.5rem"}}>戻る</span>
                                                </a>
                                            </div>
                                        </RTween>
                                    </div>
                                </RTween>
                            </RTimeline>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


/**
 * 項目の見出し
 *
 * @param {string} type - 見出しの種類. (default 'any')
 *     'none'     = 任意の入力項目 アイコンなし
 *     'any'      = 任意の入力項目 アイコンあり
 *     'required' = 必須の入力項目
 * @param {string} headerText - 項目名. 未指定は空白が入る.
 * @param {boolean | undefined} validationCheck - 検証チェック. type: 'required' のとき有効.
 *     undefined = 未検証
 *     true      = 検証OK
 *     false     = 検証NG
 * @return {*} React Element
 */
interface ITableHeaderDefaultProps {
    type: string,
    headerText?: string,
    validationCheck?: boolean | undefined,
    containerClassName?: string,
    containerStyle?: object,
}

const TableHeader = (props: ITableHeaderDefaultProps) => {
    const [validationState, setValidationState] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        //if (props.type == "required") {
        setValidationState(props.validationCheck)
        //}
    }, [props.validationCheck])

    return (
        <th>
            <div className="grid-container full">
                <div className="grid-x grid-padding-x grid-padding-y align-middle">
                    <div className="cell small-4 medium-4">
                        <TableHeaderIcon icon={props.type}/>
                    </div>
                    <div className="cell small-8 medium-8">
                        {props.headerText}
                    </div>
                </div>
            </div>
        </th>
    )
}

TableHeader.propTypes = {
    type: PropTypes.oneOf(["none", "any", "required"]),
}

TableHeader.defaultProps = {
    type: "any",
    headerText: "",
}


/**
 * 項目の見出し用アイコン
 *
 * @param {string} icon - アイコンの種類. (default 'any')
 *     'none'                = なし
 *     'any'                 = 任意の入力項目
 *     'required'            = 必須の入力項目
 *     'validationTrueIcon'  = 検証OK
 *     'validationFalseIcon' = 検証NG
 * @param {any} style - CSS
 * @return {*} React Element. icon: 'none' の場合は null.
 */
interface ITableHeaderIconDefaultProps {
    icon: string,
    style?: any,
}

const TableHeaderIcon = (props: ITableHeaderIconDefaultProps) => {
    const noneTextColor = "#ddd"
    const noneBgColor = "#ddd"

    const anyIcon = farPlusCircle
    const anyLabel = "任意"
    const anyTextColor = "#8a8a8a"
    const anyBgColor = "transparent"

    const requiredIcon = fasDotCircle
    const requiredLabel = "必須"
    const requiredTextColor = "#cc4b37"
    const requiredBgColor = "transparent"

    const validationTrueIcon = fasCheckCircle
    const validationTrueLabel = "OK"
    const validationTrueTextColor = "#fff"
    const validationTrueBgColor = "#1779ba"

    const validationFalseIcon = fasExclamationCircle
    const validationFalseLabel = "確認"
    const validationFalseTextColor = "#fff"
    const validationFalseBgColor = "#cc4b37"

    // none
    let textColor = noneTextColor
    let bgColor = noneBgColor
    let icon = undefined
    let label = ""

    if (props.icon == "any") {
        textColor = anyTextColor
        bgColor = anyBgColor
        icon = <FontAwesomeIcon icon={anyIcon} size="2x" fixedWidth/>
        label = anyLabel
    }

    if (props.icon == "required") {
        textColor = requiredTextColor
        bgColor = requiredBgColor
        icon = <FontAwesomeIcon icon={requiredIcon} size="2x" fixedWidth/>
        label = requiredLabel
    }

    if (props.icon == "validationTrue") {
        textColor = validationTrueTextColor
        bgColor = validationTrueBgColor
        icon = <FontAwesomeIcon icon={validationTrueIcon} size="2x" fixedWidth/>
        label = validationTrueLabel
    }

    if (props.icon == "validationFalse") {
        textColor = validationFalseTextColor
        bgColor = validationFalseBgColor
        icon = <FontAwesomeIcon icon={validationFalseIcon} size="2x" fixedWidth/>
        label = validationFalseLabel
    }

    return (
        <div
            className="pages-contact-item-header__icon"
            style={{
                color: textColor,
                backgroundColor: bgColor,
                ...props.style,
            }}
        >
            <div className="__icon">
                {icon}
            </div>
            <div className="__label">
                {label}
            </div>
        </div>
    )
}

TableHeaderIcon.propTypes = {
    icon: PropTypes.oneOf(["none", "any", "required", "validationTrue", "validationFalse"]),
}

TableHeaderIcon.defaultProps = {
    icon: "any",
}


/**
 * 問い合わせ種別
 *
 * @return {*} React Element
 */
interface ICategorySelectDefaultProps {
    category1Value: string,
    category1ChangeCallback: any,
    category2Value: string,
    category2ChangeCallback: any,
    isReadyCallback: any,
    disabled: boolean,
    urlParams?: any,
}

const CategorySelect = (props: ICategorySelectDefaultProps) => {
    const [defaultCategory1, setDefaultCategory1] = useState<string | undefined>(undefined)
    const [defaultCategory2, setDefaultCategory2] = useState<string | undefined>(undefined)
    const [category1Value, setCategory1Value] = useState<string>(props.category1Value)
    const [category2Value, setCategory2Value] = useState<string>(props.category2Value)
    const [isReady, setIsReady] = useState<boolean | undefined>(undefined)
    const [category1Items, setCategory1Items] = useState<any>([])
    const [category2Items, setCategory2Items] = useState<any>([])


    useEffect(() => {
        setItems()
        setSubItems()
    }, [])


    // URL引数を設定する
    useEffect(() => {
        if (props.urlParams) {
            Object.keys(props.urlParams).forEach(function (param: string, i: number) {
                if (param == "c1") {
                    setDefaultCategory1(props.urlParams[param])
                    category1Check(props.urlParams[param])
                }

                if (param == "c2") {
                    setDefaultCategory2(props.urlParams[param])
                    category2Check(props.urlParams[param])
                }
            })
        }
    }, [props.urlParams])


    useEffect(() => {
        if (defaultCategory1 !== undefined) {
            setCategory1Value(defaultCategory1)
        }
    }, [defaultCategory1])


    useEffect(() => {
        if (defaultCategory2 !== undefined) {
            setCategory2Value(defaultCategory2)
        }
    }, [defaultCategory2])


    useEffect(
        () => {
            return props.isReadyCallback(isReady)
        },
        [isReady],
    )


    useEffect(() => {
        //setCategory2Value("000")
        setSubItems()
        return props.category1ChangeCallback(category1Value)
    }, [category1Value])


    useEffect(() => {
        setSubItems()
        return props.category2ChangeCallback(category2Value)
    }, [category2Value])


    // メイン選択項目を作成する
    const setItems = () => {
        let _category1Items = []

        for (let i in categoryData) {
            _category1Items.push(
                <option
                    key={`category1Items${i}`}
                    value={categoryData[i].id}
                    defaultValue={category1Value}
                >
                    {categoryData[i].label}
                </option>,
            )
        }

        setCategory1Items(_category1Items)
    }


    // サブ選択項目を作成する
    const setSubItems = () => {
        let _category2Items = []

        // 製品開発
        if (category1Value == '0100') {
            for (let i in productDevelopmentCategoryData) {
                _category2Items.push(
                    <option
                        key={`category2ProductDevelopmentItem${i}`}
                        value={productDevelopmentCategoryData[i].id}
                        defaultValue={category2Value}
                    >
                        {productDevelopmentCategoryData[i].label}
                    </option>,
                )
            }
        }

        // 採用情報
        if (category1Value == '9100') {
            for (let i in jobCategoryData) {
                _category2Items.push(
                    <option
                        key={`category2JobItem${i}`}
                        value={jobCategoryData[i].id}
                        defaultValue={category2Value}
                    >
                        {jobCategoryData[i].label}
                    </option>,
                )
            }
        }

        setCategory2Items(_category2Items)
    }


    // メインカテゴリの入力済かチェックする
    const category1Check = (value:string) => {
        if (value == categoryData[0].id || value == categoryData[1].id || value == categoryData[3].id) {
            setIsReady(undefined)
            return
        }

        setIsReady(true)
    }


    // サブカテゴリの入力済かチェックする
    const category2Check = (value:string) => {
        if (value == "0000") {
            setIsReady(undefined)
            return
        }

        setIsReady(true)
    }


    const category1HandleChange = (event: any) => {
        const value = event.target.value
        setCategory1Value(value)

        category1Check(value)
    }


    const category2HandleChange = (event: any) => {
        const value = event.target.value
        setCategory2Value(value)

        category2Check(value)
    }


    // サブ選択フォームを作成する
    let category2 = null
    if (category1Value == categoryData[1].id || category1Value == categoryData[3].id) {
        let category2Label = ""

        switch (category1Value) {
            case categoryData[1].id:
                category2Label = "開発分野"
                break
            case categoryData[3].id:
                category2Label = "求人職種"
                break
        }

        category2 = (
            <label>{category2Label}
                <select
                    value={props.category2Value}
                    name="category2"
                    onChange={category2HandleChange}
                    disabled={props.disabled}
                    tabIndex={4}
                >
                    {category2Items}
                </select>
            </label>
        )
    }


    return (
        <Fragment>
            <label>カテゴリ
                <select
                    value={props.category1Value}
                    name="category1"
                    onChange={category1HandleChange}
                    disabled={props.disabled}
                    tabIndex={4}
                >
                    {category1Items}
                </select>
            </label>
            {category2}
        </Fragment>
    )
}

CategorySelect.defaultProps = {
    disabled: false,
}


/**
 * 送信ボタン
 *
 * @param {string} label - ボタンのラベル
 * @param {boolean} isReady - true でボタンが押せる状態になる
 * @return {*} React Element
 */
interface ISubmitButtonDefaultProps {
    label: string,
    isReady: boolean,
    onClick?: any,
}

const SubmitButton = (props: ISubmitButtonDefaultProps) => {
    const [disabled, setDisabled] = useState<boolean>(true)


    useEffect(() => {
        setToDisabled(props.isReady)
    }, [])


    useEffect(() => {
        setToDisabled(props.isReady)
    }, [props.isReady])


    // ボタンを無効にする
    const setToDisabled = (isReady: boolean) => {
        if (isReady) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }


    return (
        <button
            type="submit"
            name="send"
            className="button large primary"
            disabled={disabled}
        >
            {props.label}
        </button>
    )
}

SubmitButton.defaultProps = {
    label: "送信",
    isReady: false,
}


export default Contact
