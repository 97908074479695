import React, { Fragment } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"


// Components
import SEO from "../components/Seo"
import LayoutBase from "../components/Layout"
import PageHeader from "../components/PageHeader"


/**
 * Policy Component
 *
 * @param location {any} -
 * @return React Element {*}
 */
interface IPolicyDefaultProps {
    location: any,
}

const PolicyPage = (props: IPolicyDefaultProps) => {
    const query = useStaticQuery(graphql`
        query {
            policy_pageHeader_backgroundImage: file(relativePath: {eq: "policy_pageHeader_backgroundImage.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 2500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
        }
    `)
    const pageId = "policy"
    const pageTitle = "プライバシーポリシー"
    const location = props.location
    const { crumbs } = useBreadcrumb({
        location: location,
        crumbLabel: pageTitle,
    })

    return (
        <LayoutBase
            pageId={pageId}
            crumbs={crumbs}
            location={location}
        >
            <PageHeader
                title={pageTitle}
                backgroundImage={query.policy_pageHeader_backgroundImage.childImageSharp.fluid}
                indicators={true}
            />
            <div className="common-content-layout common-content-format">
                <HandlingPersonalInformation className="common-content-layout__section"/>
                <HandlingInformationWebsite className="common-content-layout__section"/>
            </div>
        </LayoutBase>
    )
}


/**
 * 個人情報の取り扱いについて
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
export interface IHandlingPersonalInformationDefaultProps {
    className?: string,
    style?: React.CSSProperties,
}

export const HandlingPersonalInformation = (props: IHandlingPersonalInformationDefaultProps) => {
    let rootContainerClassName = `grid-container`
    if (props.className) rootContainerClassName = `${rootContainerClassName} ${props.className}`

    return (
        <div className={rootContainerClassName} style={props.style}>
            <div id="applicationRequirements" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h1>個人情報の取扱いについて</h1>
                    <h2>お問い合わせ・電子メールからご提供いただいた情報の取り扱い</h2>
                    <p>お問い合わせ・電子メールからご提供いただいた情報は、以下の目的で利用するものとします。</p>
                    <ul>
                        <li>お問い合わせや見積依頼についての返答に利用します</li>
                        <li>打ち合わせのご連絡など業務の遂行上必要な場合に利用します</li>
                        <li>その他、何らかの理由でご連絡する必要が生じた場合に利用します</li>
                    </ul>
                    <h2 className="utility-margin-medium-top">サードパーティが取得する情報の取り扱い</h2>
                    <p>サードパーティが取得する情報の取り扱いは、各サービスの規約に基づきます。</p>
                    <ul>
                        <li>Googleアナリティクス
                            <ul>
                                <li>
                                    <a
                                        href="https://www.google.com/intl/ja/policies/privacy/"
                                        target="_blank"
                                    >
                                        プライバシーポリシー
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.google.com/intl/ja/policies/terms/"
                                        target="_blank"
                                    >
                                        利用規約
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>Google reCAPTCHA
                            <ul>
                                <li>
                                    <a
                                        href="https://www.google.com/intl/ja/policies/privacy/"
                                        target="_blank"
                                    >
                                        プライバシーポリシー
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.google.com/intl/ja/policies/terms/"
                                        target="_blank"
                                    >
                                        利用規約
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}


/**
 * ウェブサイトでの情報の取扱いについて
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
export interface IHandlingInformationWebsiteDefaultProps {
    className?: string,
    style?: React.CSSProperties,
}

export const HandlingInformationWebsite = (props: IHandlingInformationWebsiteDefaultProps) => {
    let rootContainerClassName = `grid-container`
    if (props.className) rootContainerClassName = `${rootContainerClassName} ${props.className}`

    return (
        <div className={rootContainerClassName} style={props.style}>
            <div id="applicationRequirements" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h1>ウェブサイトでの情報の取扱いについて</h1>
                    <h2>Cookie</h2>
                    <h3>サイトの利便性を向上させる用途で使用</h3>
                    <table>
                        <thead>
                        <tr>
                            <th style={{ width: "15%" }}>Name</th>
                            <th>説明</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>tb_ga</td>
                            <td>クッキーの利用通知で、googleアナリティクスの利用可否に使用しています。有効期間は「設定を保持する」にチェックをした場合は30日間、チェックをしない場合はブラウザを閉じるまでとなります。</td>
                        </tr>
                        </tbody>
                    </table>
                    <h3 className="utility-margin-small-top">Googleアナリティクス</h3>
                    <p>
                        より良いサービスを提供できるようアクセス解析ツール「Googleアナリティクス」を使用しています。
                        アクセス解析ではいくつかのCookieを設定します。設定されるCookieの詳しい情報は <a
                        href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=ja"
                        target="_blank">Google社のガイドライン</a> をご覧ください。
                    </p>
                    <h3 className="utility-margin-small-top">Google reCAPTCHA</h3>
                    <p>
                        botによるスパム対策としてお問い合わせページに「Google reCAPTCHA」を使用しています。
                        reCAPTCHAではいくつかのCookieを設定します。設定されるCookieの詳しい情報は<a
                        href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=ja"
                        target="_blank">Google社のガイドライン</a> をご覧ください。
                    </p>
                    <h2 className="utility-margin-medium-top">アクセス解析</h2>
                    <p>
                        当サイトでは、Googleのアクセス解析ツール「Googleアナリティクス」を利用しています。
                        このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
                        このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
                        この機能はCookieを無効にすることで収集を拒否することが出来ます。
                        Cookieを無効化する場合は、ご利用しているブラウザの設定をご確認ください。
                        この規約に関する詳しい情報は <a href="https://policies.google.com/privacy?hl=ja"
                                          target="_blank">Google社のガイドライン</a> をご覧ください。
                    </p>
                    <h2 className="utility-margin-medium-top">bot対策</h2>
                    <p>
                        当サイトでは、botによるスパム対策としてお問い合わせページに「Google reCAPTCHA」を使用しています。
                        reCAPTCHAはハードウェアやソフトウェアの情報（デバイスやアプリケーションのデータ、完全性チェックの結果など）を収集し、分析のためにそのデータをGoogleに送信することにより動作します。
                        <br/>
                        <a href="https://developers.google.com/terms/" target="_blank">Google API
                            利用規約</a> に同意できない、または収集した情報をGoogleに送信したくない場合は <a
                        href="mailto:info@tranceboot.com">info@tranceboot.com</a> 宛へメールを送信してもお問い合わせいただけます。
                        情報の取り扱いについての詳しい情報は <a href="https://policies.google.com/privacy?hl=ja"
                                              target="_blank">Google社のガイドライン</a> をご覧ください。
                    </p>
                    <h2 className="utility-margin-medium-top">他サイトからの埋め込みコンテンツ</h2>
                    <p>
                        このサイトの投稿には埋め込みコンテンツ (動画、画像、投稿など) が含まれます。
                        他サイトからの埋め込みコンテンツは、訪問者がそのサイトを訪れた場合とまったく同じように振る舞います。
                        これらのサイトは、あなたのデータを収集したり、Cookieを使ったり、サードパーティによる追加トラッキングを埋め込んだり、あなたと埋め込みコンテンツとのやりとりを監視したりすることがあります。
                        アカウントを使ってそのサイトにログイン中の場合、埋め込みコンテンツとのやりとりのトラッキングも含まれます。
                    </p>
                    <h2 className="utility-margin-medium-top">データの第三者への開示</h2>
                    <p>
                        弊社は原則として、ご提供いただいた個人情報を第三者に開示することはいたしません。
                        但し、以下のような場合、個人の情報を開示することができるものとします。
                    </p>
                    <ul>
                        <li>個人情報提供者本人の同意がある場合</li>
                        <li>法令等に基づく要請があり、開示が必要であると判断される場合</li>
                    </ul>
                    <h2 className="utility-margin-medium-top">データの取扱についてのお問い合わせ</h2>
                    <p>
                        ご提供いただいた個人情報の取扱いに関するご要望・お問い合わせは、お問いあわせページからご連絡ください。
                    </p>
                    <h2 className="utility-margin-medium-top">変更および通知について</h2>
                    <p>
                        弊社は、この「個人情報保護方針（プライバシーポリシー）」の内容を、事前に予告なく変更することがあります。
                        変更内容につきましては、随時当ページにてお知らせいたします。
                    </p>
                </div>
            </div>
        </div>
    )
}


export default PolicyPage
